.slider {
    width: 95%;
    display: flex;
    gap: 10px;
    font-size: 0;
    overflow-x: auto;
    position: relative;
    left: 2.5%;
    margin:  0 50px;
    cursor: grab;
    scrollbar-color: blue;
}
.slider:active {
    cursor: grabbing;
}
.slider img {
    width: 300px;
        height: 380px;
        object-fit: cover;
}



.heroSec {
  background-image: url('../../Assets/img14.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
    .slider img {
        width: 300px;
        height: 380px;
    }
    .slider {
        left: 4.5%;
    }
}