@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Cairo', Arial, Helvetica, sans-serif;
}

body {
  min-height: 100vh;
  background-color: #efefef;
}
